import NativeMeshInteractor from "./NativeMeshInteractor";
import DataHandler from "../handler/DataHandler";

export default {
    accessToken: "",
    tokenExpiry: 0,
    async setAccessToken() {
        //Fallback case - Prevent access token calls to native if customerId is available
        if (!DataHandler.getUserCustomerId()) {
            const tokenObj = JSON.parse(await NativeMeshInteractor.getAccessTokenV2());
            this.accessToken = tokenObj.token;
            this.tokenExpiry = tokenObj.expirationTime;
        }
    },
    isAccessTokenExpired() {
        return Date.now() > this.tokenExpiry;
    }
};