import {getAssociateExperienceBaseURL} from "./BaseURLUtils";

/**
 * Gets the query parameter string. It combines the query parameters in the current URL
 * with custom parameters that are passed.
 * @param customParams custom parameters if any.
 * @return {string} Query parameter string in the format "?key1=value1&key2=value2"
 */
export const getQueryParamsString = (customParams) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const defaultParams = Object.fromEntries(urlSearchParams.entries());
    const allParams = {...defaultParams, ...customParams};
    return `?${Object.keys(allParams).map(key => key + '=' + allParams[key]).join('&')}`;
}

/**
 * Calculates the URL for AssociateExperience PWA.
 * @param stage
 * @param region
 * @param queryParams custom query parameters if any.
 * @param path custom path parameter if any.
 * @return {string} AssociateExperience PWA URL.
 */
export const getAssociateExperienceURL = (stage, region, path, queryParams) => {
    const baseUrl = getAssociateExperienceBaseURL(stage, region);
    const queryParamsString = getQueryParamsString(queryParams);
    if (path) {
        // Add "/" in the beginning if pathParams does not begin with it.
        if (path.startsWith("/")) {
            return baseUrl + "/" + queryParamsString + path;
        }
        return baseUrl + "/" + queryParamsString + "/" + path;
    }
    return baseUrl + "/" + queryParamsString;
}