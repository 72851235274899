export default {
    SUBSCRIBABLE_EVENTS : Object.freeze({
        GET_TOKEN : "GET_TOKEN",
        GET_TOKEN_V2: "GET_TOKEN_V2",
        ZEBRA_CREATE_CONNECTION: "ZEBRA_CREATE_CONNECTION",
        ZEBRA_PRINT_LABEL: "ZEBRA_PRINT_LABEL",
        ZEBRA_PRINTER_STATUS: "ZEBRA_PRINTER_STATUS",
        ZEBRA_PRINTER_RESOLUTION: "ZEBRA_PRINTER_RESOLUTION",
        GET_AVERY_CONNECTION_STATUS: "GET_AVERY_CONNECTION_STATUS",
        AVERY_CHANGE_PRINTER: "AVERY_CHANGE_PRINTER",
        ZEBRA_CREATE_USB_CONNECTION: "ZEBRA_CREATE_USB_CONNECTION"
    })
}